<template lang="">
    <div>
        <div class="field col-12">
            <div class="formgrid grid">
                <div class="field col">
                    <label for="clientes">Clientes</label>
                    <MultiSelectCliente id="clientes" v-model="filtros.clientes" />
                </div>
                <div class="field col">
                    <label for="unidades">Unidades</label>
                    <MultiSelectUnidade id="unidades" v-model="filtros.unidades" />
                </div>
                <div class="field col">
                    <label for="funcionarios">Funcionarios</label>
                    <MultiselectFuncionario id="funcionarios" v-model="filtros.funcionarios" />
                </div>
            </div>
        </div>
        <DataTable
            :loading="loading"
            dataKey="id"
            :value="records"
            filterDisplay="menu"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            selectionMode="single"
            :rowClass="defaultRowClass"
            @sort="onSort"
            sortField="id"
            :sortOrder="1"
            removableSort
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="msgErro" :style="{ padding: '1rem 0.5rem' }">
                <template #body="slotProps">
                    <i
                        v-if="slotProps.data.msgErro"
                        v-tooltip.bottom="slotProps.data.msgErro"
                        class="pi pi-info-circle"
                        :style="{ color: 'red' }"
                    ></i>
                </template>
            </Column>

            <Column field="funcionario.name" :sortable="true" sortField="funcionario.name" header="Funcionario"></Column>
            <Column field="cidade.cidade" :sortable="true" sortField="cidade.cidade" header="Cidade"></Column>
            <Column field="prestador.name" :sortable="true" sortField="prestador.name" header="Prestador"></Column>
            <Column field="dataSugestaoInicial" :sortable="true" sortField="dataSugestaoInicial" header="Datas de sugestão">
                <template #body="slotProps">
                    <span v-if="slotProps.data.dataSugestaoInicial">{{ $filters.formatDateOnly(slotProps.data.dataSugestaoInicial) }}</span> -
                    <span v-if="slotProps.data.dataSugestaoFinal">{{ $filters.formatDateOnly(slotProps.data.dataSugestaoFinal) }}</span>
                </template>
            </Column>
            <Column field="periodo" :sortable="true" sortField="periodo" header="Período">
                <template #body="slotProps">
                    <span>{{ $filters.periodo(slotProps.data.periodo) }}</span>
                </template>
            </Column>
            <Column field="observacao" :sortable="true" sortField="observacao" header="Observação">
                <template #body="slotProps">
                    <Button
                        v-if="slotProps.data.observacao"
                        label="Visualizar observação"
                        class="p-button-link"
                        @click="onClickObs(slotProps.data)"
                    />
                    <Button v-if="!slotProps.data.observacao" label="Sem observação" class="p-button-link" @click="onClickObs(slotProps.data)" />
                </template>
            </Column>
            <Column field="pcd" :sortable="true" sortField="pcd" header="Solicita laudo PCD">
                <template #body="slotProps">
                    <span>{{ $filters.simNao(slotProps.data.pcd) }}</span>
                </template>
            </Column>
            <Column field="resideForaDoPais" :sortable="true" sortField="resideForaDoPais" header="Reside fora do país">
                <template #body="slotProps">
                    <span>{{ $filters.simNao(slotProps.data.resideForaDoPais) }}</span>
                </template>
            </Column>
            <Column bodyClass="text-left" headerStyle="width: 5rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-text p-button-info" v-tooltip="'Editar'" @click="onClickEditRecord(slotProps.data)" />
                </template>
            </Column>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <Button
                        v-if="!slotProps.data.excluido"
                        :loading="slotProps.data.loadingExcluir"
                        icon="pi pi-trash"
                        class="p-button-text p-button-danger"
                        v-tooltip="'Excluir'"
                        @click="onClickExcluir(slotProps.data)"
                    />
                    <Button
                        v-if="slotProps.data.excluido"
                        :loading="slotProps.data.loadingExcluir"
                        icon="pi pi-sync"
                        class="p-button-text p-button-secondary"
                        v-tooltip="'Desfazer excluir'"
                        @click="onClickDesfazerExcluir(slotProps.data)"
                    />
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-show="!loading"
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>

        <div class="col-12 p-0 pb-1">
            <div class="flex justify-content-end">
                <div class="flex align-items-center justify-content-center w-20rem font-bold text-white">
                    <Button label="Editar em massa" icon="pi pi-check" class="p-button p-button-primary" @click="onClickEditarEmMassa" />
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="flex justify-content-end">
                <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                    <Button
                        label="Confirmar telefone/Email"
                        :loading="loadingConfirmarTelefoneEmail"
                        icon="pi pi-check"
                        class="p-button p-button-primary mr-2 w-30rem"
                        @click="onClickConfirmarTelefoneEmail"
                    />
                    <Button label="Cancelar" icon="pi pi-times" class="p-button p-button-danger w-20rem" @click="showCancelarRecord = true" />

                    <AppDeleteDialog
                        v-model:visible="showCancelarRecord"
                        customTitle="Deseja realmente cancelar a convocação?"
                        labelConfirm="Cancelar"
                        @onConfirm="onClickCancelar"
                        @onClose="onCloseCancelar"
                    >
                    </AppDeleteDialog>
                </div>
            </div>
        </div>

        <Dialog header="Observação" v-model:visible="showObsDialog" :breakpoints="{ '560px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
            <p>{{ actionItem.observacao || 'Sem observação' }}</p>
        </Dialog>

        <FuncionarioEditDialog
            v-if="showEditRecordDialog"
            v-model:visible="showEditRecordDialog"
            v-bind:form="actionItem"
            @onClose="showEditRecordDialog = false"
            @onSalvar="onSalvarEditFuncionario"
        />

        <EdicaoMassaDialog
            v-if="showEdicaoMassaDialog"
            v-model:visible="showEdicaoMassaDialog"
            v-bind:form="actionItem"
            @onClose="showEdicaoMassaDialog = false"
            @onSalvar="onSalvarEdicaoMassa"
        />
    </div>
</template>
<script>
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import ConvocacaoService from '../services/ConvocacaoService';
import { showSuccess, showError } from '@/utils/Toast';
import FuncionarioEditDialog from './FuncionarioEditDialog';
import EdicaoMassaDialog from './EdicaoMassaDialog';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente';
import MultiSelectUnidade from '../../unidades/components/MultiSelectUnidade';
import MultiselectFuncionario from '../../funcionarios/components/MultiselectFuncionario';

export default {
    components: { FuncionarioEditDialog, EdicaoMassaDialog, MultiSelectCliente, MultiSelectUnidade, MultiselectFuncionario },
    data() {
        return {
            $service: null,
            $serviceConvocacao: null,
            loading: false,
            loadingConfirmarTelefoneEmail: false,
            showObsDialog: false,
            showEditRecordDialog: false,
            showEdicaoMassaDialog: false,
            records: [],
            actionItem: {},
            total: 0,
            page: 1,
            perPage: 10,
            sort: null,
            filter: [],
            showCancelarRecord: false,
            filtrosExtras: {
                clienteIds: null,
                unidadeIds: null,
                funcionarioIds: null
            },
            filtros: {
                clientes: null,
                unidades: null,
                funcionarios: null
            }
        };
    },
    watch: {
        'filtros.clientes'() {
            this.filtrosExtras.clienteIds = this.filtros.clientes.map((p) => p.id);
            this.load();
        },
        'filtros.unidades'() {
            this.filtrosExtras.unidadeIds = this.filtros.unidades.map((p) => p.id);
            this.load();
        },
        'filtros.funcionarios'() {
            this.filtrosExtras.funcionarioIds = this.filtros.funcionarios.map((p) => p.id);
            this.load();
        }
    },
    mounted() {
        this.$service = new ConvocacaoFuncionarioService();
        this.$serviceConvocacao = new ConvocacaoService();
        this.load();
    },
    methods: {
        async onClickCancelar() {
            try {
                await this.$serviceConvocacao.cancelar(this.$route.params.id);
                showSuccess(this.$toast, 'Cancelado com sucesso');
                this.showCancelarRecord = false;
                this.$router.push(`/gestaoMedica/request`);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onCloseCancelar() {
            this.showCancelarRecord = false;
        },
        defaultRowClass(data) {
            if (data.excluido) {
                return 'row-excluida';
            }

            return '';
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAllFuncionario(this.$route.params.id, {
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtras
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        onSort(event) {
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        async onClickEditRecord(record) {
            const response = await this.$service.findById(record.id);
            this.actionItem = {
                ...record,
                procedimentos: response.data?.procedimentos
            };
            if (!this.actionItem.funcionario) {
                showError(this.$toast, 'Não é permitido edição quando não existe funcionário. Exclua este funcionário ou refaça a convocação.');
                return;
            }
            this.showEditRecordDialog = true;
        },
        onSalvarEditFuncionario() {
            this.showEditRecordDialog = false;
            this.load();
        },
        onClickEditarEmMassa() {
            this.showEdicaoMassaDialog = true;
        },
        onSalvarEdicaoMassa() {
            this.showEdicaoMassaDialog = false;
            this.load();
        },
        async onClickExcluir(record) {
            try {
                record.loadingExcluir = true;
                await this.$service.excluir(record.id);
                record.excluido = true;
                showSuccess(this.$toast, 'Excluído com sucesso');
                record.loadingExcluir = false;
            } catch (error) {
                record.loadingExcluir = false;
                showError(this.$toast, error);
            }
        },
        async onClickDesfazerExcluir(record) {
            try {
                record.loadingExcluir = true;
                await this.$service.desfazerExcluir(record.id);
                record.excluido = false;
                showSuccess(this.$toast, 'Exclusão desfeita com sucesso');
                record.loadingExcluir = false;
            } catch (error) {
                record.loadingExcluir = false;
                showError(this.$toast, error);
            }
        },
        onClickObs(record) {
            this.actionItem = record;
            this.showObsDialog = true;
        },
        async onClickConfirmarTelefoneEmail() {
            try {
                await this.$serviceConvocacao.confirmarFuncionarios(this.$route.params.id);
                this.$router.push(`/convocacao-exames/${this.$route.params.id}/confirmacao`);
                showSuccess(this.$toast, 'Funcionários confirmados com sucesso!');
            } catch (error) {
                showError(this.$toast, error);
                this.load();
            }
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
::v-deep(.row-excluida) {
    color: #9e9e9e !important;
    text-decoration: line-through;
}
</style>
