<template lang="">
    <Dialog :style="{ width: '1050px' }" header="Edição em massa" class="p-fluid" :modal="true">
        <div class="formgrid grid">
            <div class="field col">
                <h5 class="mb-4">Selecione os funcionários</h5>

                <div class="field col-12">
                    <div class="formgrid grid">
                        <div class="field col">
                            <label for="clientes">Clientes</label>
                            <MultiSelectCliente id="clientes" v-model="filtros.clientes" />
                        </div>
                        <div class="field col">
                            <label for="unidades">Unidades</label>
                            <MultiSelectUnidade id="unidades" v-model="filtros.unidades" />
                        </div>
                        <div class="field col">
                            <label for="funcionarios">Funcionarios</label>
                            <MultiselectFuncionario id="funcionarios" v-model="filtros.funcionarios" />
                        </div>
                    </div>
                </div>

                <PickList
                    v-model="funcionarios"
                    v-model:selection="selection"
                    listStyle="height:342px"
                    dataKey="id"
                    showSourceControls="false"
                    showTargetControls="false"
                    :striped-rows="true"
                >
                    <template #sourceheader> Disponíveis </template>
                    <template #targetheader> Selecionados </template>
                    <template #item="slotProps">
                        {{ slotProps.item.funcionario.name }}
                    </template>
                </PickList>
            </div>
            <div class="field col">
                <h5 class="mb-4">Atribuir nos selecionados</h5>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="quemAgenda">Quem agenda</label>
                        <Dropdown
                            class="w-full"
                            id="quemAgenda"
                            v-model="form.quemAgenda"
                            :options="agendaAvisa"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione quem agenda"
                        />
                    </div>
                    <div class="field col">
                        <label for="quemAvisa">Quem avisa</label>
                        <Dropdown
                            class="w-full"
                            id="quemAvisa"
                            v-model="form.quemAvisa"
                            :options="agendaAvisa"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione quem avisa"
                        />
                    </div>
                </div>
                <div class="field">
                    <label for="cidade">Cidade</label>
                    <DropDownCidade id="cidade" v-model="form.cidade" :modelValue="form.cidade" />
                </div>
                <div class="field">
                    <label for="cidade">Prestador</label>
                    <DropdownPrestadorCliente
                        v-model="form.prestador"
                        :idCliente="clienteIds"
                        :idCidade="form.cidadeId"
                        :autoLoad="true"
                        :disabled="!form.cidade"
                        :class="{ 'p-invalid': submitted && !form.prestador }"
                    />
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="rangeDatas">Datas de sugestão</label>
                        <Calendar
                            id="rangeDatas"
                            v-model="datasSugestao"
                            autocomplete="off"
                            dateFormat="dd/mm/yy"
                            selectionMode="range"
                            :showIcon="true"
                            placeholder="Range de datas"
                        />
                    </div>
                    <div class="field col">
                        <label for="periodo">Periodo</label>
                        <Dropdown
                            id="periodo"
                            v-model="form.periodo"
                            :options="periodos"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                        ></Dropdown>
                    </div>
                </div>
                <div class="field">
                    <label for="observação">Observação</label>
                    <Textarea :autoResize="true" rows="3" cols="20" id="observação" v-model="form.observacao" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickClose" />
            <Button class="p-button p-button-primary ml-3" :loading="loadingSalvar" icon="pi pi-check" label="Salvar" @click="onClickSalvar" />
        </template>
    </Dialog>
</template>
<script>
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import DropDownCidade from '../../cidades/components/DropdownCidade.vue';
import DropdownPrestadorCliente from '../../prestadores/components/DropdownPrestadorCliente';
import { showSuccess, showError } from '@/utils/Toast';
import { getProfile, nomeEmpresaUsuarioReduzido, nomeUsuarioReduzido } from '@/services/auth';
import QuemAgendaAvisa from '@/enums/QuemAgendaAvisa';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente';
import MultiSelectUnidade from '../../unidades/components/MultiSelectUnidade';
import MultiselectFuncionario from '../../funcionarios/components/MultiselectFuncionario';

export default {
    name: 'EdicaoMassaDialog',
    components: { DropDownCidade, DropdownPrestadorCliente, MultiSelectCliente, MultiSelectUnidade, MultiselectFuncionario },
    emits: ['onClose', 'onSalvar'],
    data() {
        return {
            funcionarios: [],
            selection: [],
            agendaAvisa: [],
            form: {},
            profile: {},
            $service: null,
            loadingSalvar: false,
            submitted: false,
            datasSugestao: [],
            showSourceControls: true,
            showTargetControls: false,
            periodos: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 }
            ],
            quemAgendaAvisa: QuemAgendaAvisa,
            filtrosExtras: {
                clienteIds: null,
                unidadeIds: null,
                funcionarioIds: null
            },
            filtros: {
                clientes: null,
                unidades: null,
                funcionarios: null
            }
        };
    },
    watch: {
        'form.cidade'() {
            this.form.cidadeId = this.form?.cidade?.id;
        },
        'form.prestador'() {
            this.form.prestadorId = this.form?.prestador?.id;
        },
        datasSugestao: {
            handler() {
                this.form.dataSugestaoInicial = this.datasSugestao[0];
                this.form.dataSugestaoFinal = this.datasSugestao[1];
            },
            deep: true
        },
        'filtros.clientes'() {
            this.filtrosExtras.clienteIds = this.filtros.clientes.map((p) => p.id);
            this.load();
        },
        'filtros.unidades'() {
            this.filtrosExtras.unidadeIds = this.filtros.unidades.map((p) => p.id);
            this.load();
        },
        'filtros.funcionarios'() {
            this.filtrosExtras.funcionarioIds = this.filtros.funcionarios.map((p) => p.id);
            this.load();
        }
    },
    computed: {
        clienteIds() {
            const funcionariosSelecionados = this.funcionarios[1];

            return funcionariosSelecionados?.map((p) => p.clienteId);
        }
    },
    mounted() {
        this.$service = new ConvocacaoFuncionarioService();
        this.load();
    },
    methods: {
        async load() {
            this.profile = await getProfile();
            await this.definirQuemAgendaAvisa();
            await this.buscarFuncionarios();
        },
        async buscarFuncionarios() {
            const response = await this.$service.findAllFuncionario(this.$route.params.id, {
                page: 1,
                limit: 9999,
                filtrosExtras: this.filtrosExtras
            });
            const comFuncionarios = response.data?.items?.filter((p) => p.funcionario) ?? [];
            this.funcionarios = [comFuncionarios, []];
        },
        onClickClose() {
            this.$emit('onClose');
        },
        async onClickSalvar() {
            try {
                this.loadingSalvar = true;
                const funcionariosSelecionados = this.funcionarios[1];
                const funcionarioIds = funcionariosSelecionados.map((p) => p.funcionarioId);
                const data = {
                    ...this.form,
                    funcionarioIds
                };
                await this.$service.salvarMassa(this.$route.params.id, data);
                this.loadingSalvar = false;
                showSuccess(this.$toast, 'Alterado com sucesso');
                this.$emit('onSalvar');
            } catch (error) {
                this.loadingSalvar = false;
                showError(this.$toast, error);
            }
        },
        async definirQuemAgendaAvisa() {
            if (this.profile?.quemAgenda && this.profile?.quemAvisa) {
                this.quemAgenda = this.profile?.quemAgenda;
                this.quemAvisa = this.profile?.quemAvisa;
            }
            const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
            const nomeReduzidoUsuario = await nomeUsuarioReduzido(58);
            this.agendaAvisa = [
                { label: nomeReduzidoUsuario, value: this.quemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: this.quemAgendaAvisa.EMPRESA }
            ];
        }
    }
};
</script>
