<template>
    <Dialog :style="{ width: '650px' }" :header="`Editando ${formLocal.funcionario?.name}`" class="p-fluid" :modal="true">
        <div class="formgrid grid">
            <div class="field col">
                <label for="quemAgenda">Quem agenda</label>
                <Dropdown
                    class="w-full"
                    id="quemAgenda"
                    v-model="formLocal.quemAgenda"
                    :options="agendaAvisa"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Selecione quem agenda"
                />
            </div>
            <div class="field col">
                <label for="quemAvisa">Quem avisa</label>
                <Dropdown
                    class="w-full"
                    id="quemAvisa"
                    v-model="formLocal.quemAvisa"
                    :options="agendaAvisa"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Selecione quem avisa"
                />
            </div>
        </div>
        <div class="field">
            <label for="cidade">Cidade</label>
            <DropDownCidade id="cidade" v-model="formLocal.cidade" :modelValue="formLocal.cidade" />
        </div>
        <div class="field">
            <label for="cidade">Prestador</label>
            <DropdownPrestadorCliente
                v-model="formLocal.prestador"
                :idCliente="formLocal.clienteId"
                :idCidade="formLocal.cidadeId"
                :autoLoad="true"
                :disabled="!formLocal.cidade"
                :class="{ 'p-invalid': submitted && !formLocal.prestador }"
            />
        </div>
        <div class="formgrid grid">
            <div class="field col">
                <label for="rangeDatas">Datas de sugestão</label>
                <Calendar
                    id="rangeDatas"
                    v-model="datasSugestao"
                    autocomplete="off"
                    dateFormat="dd/mm/yy"
                    selectionMode="range"
                    :showIcon="true"
                    placeholder="Range de datas"
                />
            </div>
            <div class="field col">
                <label for="periodo">Periodo</label>
                <Dropdown
                    id="periodo"
                    v-model="formLocal.periodo"
                    :options="periodos"
                    optionValue="value"
                    optionLabel="label"
                    placeholder="Selecione..."
                ></Dropdown>
            </div>
        </div>
        <div class="field">
            <label for="observação">Observação</label>
            <Textarea :autoResize="true" rows="3" cols="20" id="observação" v-model="formLocal.observacao" />
        </div>
        <div class="field-checkbox">
            <InputSwitch id="binary" v-model="formLocal.resideForaDoPais" />
            <label for="binary">Funcionario reside fora do país</label>
        </div>
        <div class="field-checkbox">
            <InputSwitch id="pcd" v-model="formLocal.pcd" />
            <label for="pcd">Funcionario é PCD?</label>
        </div>

        <h5 class="mb-4">Procedimentos</h5>
        <DataTable
            dataKey="id"
            :value="formLocal.procedimentos"
            filterDisplay="menu"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            selectionMode="single"
            sortField="id"
            :sortOrder="1"
            removableSort
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="msgErro" :style="{ padding: '1rem 0.5rem' }">
                <template #body="slotProps">
                    <i
                        v-if="slotProps.data.msgErro"
                        v-tooltip.bottom="slotProps.data.msgErro"
                        class="pi pi-info-circle"
                        :style="{ color: 'red' }"
                    ></i>
                </template>
            </Column>

            <Column field="codigoExameSoc" :sortable="true" sortField="codigoExameSoc" header="Código SOC"></Column>
            <Column field="nomeExameSoc" :sortable="true" sortField="nomeExameSoc" header="Nome SOC"></Column>
            <Column field="dataRefazer" :sortable="true" sortField="dataRefazer" header="Data refazer">
                <template #body="slotProps">
                    <span v-if="slotProps.data.dataRefazer">{{ $filters.formatDateOnly(slotProps.data.dataRefazer) }}</span>
                </template>
            </Column>
            <Column field="dataResultado" :sortable="true" sortField="dataResultado" header="Data resultado">
                <template #body="slotProps">
                    <span v-if="slotProps.data.dataResultado">{{ $filters.formatDateOnly(slotProps.data.dataResultado) }}</span>
                </template>
            </Column>
            <Column field="dataUltimoPedido" :sortable="true" sortField="dataUltimoPedido" header="Data último pedido">
                <template #body="slotProps">
                    <span v-if="slotProps.data.dataUltimoPedido">{{ $filters.formatDateOnly(slotProps.data.dataUltimoPedido) }}</span>
                </template>
            </Column>
            <Column field="periodicidade" :sortable="true" sortField="periodicidade" header="Periodicidade"></Column>
        </DataTable>

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickClose" />
            <Button class="p-button p-button-primary ml-3" :loading="loadingSalvar" icon="pi pi-check" label="Salvar" @click="onClickSalvar" />
        </template>
    </Dialog>
</template>
<script>
import moment from 'moment-timezone';
import DropDownCidade from '../../cidades/components/DropdownCidade.vue';
import DropdownPrestadorCliente from '../../prestadores/components/DropdownPrestadorCliente';
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import { showSuccess, showError } from '@/utils/Toast';
import { getProfile, nomeEmpresaUsuarioReduzido, nomeUsuarioReduzido } from '@/services/auth';
import QuemAgendaAvisa from '@/enums/QuemAgendaAvisa';

export default {
    name: 'FuncionarioEditDialog',
    components: { DropDownCidade, DropdownPrestadorCliente },
    emits: ['onClose', 'onSalvar'],
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'form',
        event: 'formchange'
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    watch: {
        'formLocal.cidade'() {
            this.formLocal.cidadeId = this.formLocal?.cidade?.id;
        },
        'formLocal.prestador'() {
            this.formLocal.prestadorId = this.formLocal?.prestador?.id;
        },
        datasSugestao: {
            handler() {
                this.formLocal.dataSugestaoInicial = this.datasSugestao[0];
                this.formLocal.dataSugestaoFinal = this.datasSugestao[1];
            },
            deep: true
        }
    },
    data() {
        return {
            $service: null,
            loadingSalvar: false,
            submitted: false,
            datasSugestao: [],
            agendaAvisa: [],
            profile: {},
            periodos: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 }
            ],
            quemAgendaAvisa: QuemAgendaAvisa
        };
    },
    async mounted() {
        this.$service = new ConvocacaoFuncionarioService();

        this.datasSugestao = [];

        if (this.formLocal.dataSugestaoInicial) {
            const dataSugestaoInicial = moment.tz(this.formLocal.dataSugestaoInicial, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
            this.datasSugestao.push(dataSugestaoInicial);
        }

        if (this.formLocal.dataSugestaoFinal) {
            const dataSugestaoFinal = moment.tz(this.formLocal.dataSugestaoFinal, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
            this.datasSugestao.push(dataSugestaoFinal);
        }

        this.load();
    },
    methods: {
        async load() {
            this.profile = await getProfile();
            await this.definirQuemAgendaAvisa();
        },
        onClickClose() {
            this.$emit('onClose');
        },
        async onClickSalvar() {
            try {
                this.loadingSalvar = true;
                await this.$service.save(this.form);
                this.loadingSalvar = false;
                showSuccess(this.$toast, 'Alterado com sucesso');
                this.$emit('onSalvar');
            } catch (error) {
                this.loadingSalvar = false;
                showError(this.$toast, error);
            }
        },
        async definirQuemAgendaAvisa() {
            if (this.profile?.quemAgenda && this.profile?.quemAvisa) {
                this.quemAgenda = this.profile?.quemAgenda;
                this.quemAvisa = this.profile?.quemAvisa;
            }
            const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
            const nomeReduzidoUsuario = await nomeUsuarioReduzido(58);
            this.agendaAvisa = [
                { label: nomeReduzidoUsuario, value: this.quemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: this.quemAgendaAvisa.EMPRESA }
            ];
        }
    }
};
</script>
